import React, { useState } from "react";
import logo from "../assets/ges_logo.png";

export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleLinkClick = () => {
        setIsOpen(false);
    };

    return (
        <nav id="navbar" className="poppins-regular min-w-[100%] fixed top-0 flex h-24 bg-white text-black shadow-sm" role="navigation">
            <div className="container w-full mx-auto flex flex-row justify-between items-center px-4">
                <a href="/" className="inline-flex justify-center items-center">
                    <img src={logo} alt="logo" className="h-10 w-10" />
                    <div className="flex flex-col justify-center pl-1">
                        <p className="text-2xl text-cyan-600 font-semibold">BEYOĞLU |
                            <span className="text-green-500 ml-2">
                                GES
                            </span>
                        </p>
                    </div>
                </a>
                <div className="md:flex hidden flex-row justify-center items-center px-4 cursor-pointer text-sm" id="navbar-sticky">
                    <a href="#hakkimizda" className="mr-4 py-1 hover:text-cyan-500 transition-all">Hakkımızda</a>
                    <a href="#hizmetlerimiz" className="mx-4 py-1 hover:text-cyan-500 transition-all">Hizmetlerimiz</a>
                    <a href="#iletisim" className="ml-4 py-1 hover:text-cyan-500 transition-all">İletişim</a>
                </div>
                <button onClick={toggleMenu} className="md:hidden flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </button>
            </div>
            <div id="menu" className={`md:hidden ${isOpen ? 'block' : 'hidden'} absolute top-24 left-0 right-0 bg-white text-black shadow-md transition-all`}>
                <ul className="flex flex-col p-4">
                    <li className="py-2 hover:bg-gray-100">
                        <a href="#hakkimizda" className="block text-sm" onClick={handleLinkClick}>Hakkımızda</a>
                    </li>
                    <li className="py-2 hover:bg-gray-100">
                        <a href="#hizmetlerimiz" className="block text-sm" onClick={handleLinkClick}>Hizmetlerimiz</a>
                    </li>
                    <li className="py-2 hover:bg-gray-100">
                        <a href="#iletisim" className="block text-sm" onClick={handleLinkClick}>İletişim</a>
                    </li>
                </ul>
            </div>
        </nav>
    );
}
